import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import DefaultLayout from '@/layouts/default.vue'
import HomeLayout from '@/layouts/homeLayout.vue'
import AuthLayout from '@/layouts/authLayout.vue'
import VConsole from 'vconsole'
import ScrollLoader from 'vue-scroll-loader'
// import * as Sentry from "@sentry/vue";
import '@/assets/global.scss'
import * as Sentry from "@sentry/vue";

Sentry.init({
  Vue,
  dsn: "https://c62dd08377ff4d09bab39ff33e840fe9@o349622.ingest.sentry.io/6008391",
  release: "the-street-frontend@" + process.env.VUE_APP_COMMIT_REF,
  environment: process.env.VUE_APP_MODE
});

// handle liff auth params in url
const queryString = decodeURIComponent(window.location.search).replace("liff.state=?", "");

const urlParams = new URLSearchParams(queryString);

// check query string have vconsole == '1' ?
const checkVconsole = urlParams.get('vconsole');
if (checkVconsole) {
  if (checkVconsole == '1') {
    try {
      window.vConsole = new VConsole();
    } catch (error) {
      console.log(error)
    }
  }
}

Vue.prototype.$numberWithCommas = (number) => {
  return new Intl.NumberFormat().format(number)
}

// Vue.prototype.$numberWithCommasDescription = (text) => {
//   return text.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
// }

Vue.use(ScrollLoader)

Vue.component('defaultLayout', DefaultLayout)
Vue.component('homeLayout', HomeLayout)
Vue.component('authLayout', AuthLayout)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
