import Vue from 'vue'
import Vuex from 'vuex'

import { points } from './Points'
import { users } from './User'

Vue.use(Vuex)

export const state = {
  checkMkt: false,
}

export const mutations = {
  TOGGLE_CHECK_MKT(state) {
    state.checkMkt = !state.checkMkt
    return state
  },
}

export const actions = {
  toggleCheckMkt() {
    return mutations.TOGGLE_CHECK_MKT(state)
  },
}

export const getters = {
  checkMkt(state) {
    return state.checkMkt
  },
}

export const modules = { points, users }

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  modules,
})
