const envUseLiff = ['production', 'uat', 'dev'] // add 'development' for test real liff in local (must be ready line service)
export default {
  mockLiffMode: !envUseLiff.includes(process.env.VUE_APP_MODE),
  mockData: {
    profile: {
      displayName: 'devdev',
      pictureUrl: 'https://i.imgur.com/xPyGDjE.jpg',
      statusMessage: 'it good day',
      userId: 'TEST_LIFF_USERID',
    },
    accessToken:
      'eyJhbGciOiJIUzI1NiJ9.NW7Gm4DrKxzsLnTd458lVwx7mjlPW8kwXiOU6otu3pbQ3Pe6JnARolI0U9qkiQFdaCJFjW5V05XT4sRpUuM6WXfyd150cNJylWb6_8_uW45rEYli9lRqGs7Wvq73gZzqB-plTUcUsgsYipX7AFkFbsNSHSEVQEvOToYC0IQ5iq0.UI0qUV1V5KaiAOu3PMcKCInFo7mGhgcnWeiwz8ErrXU',
  },
}
