import { REQUEST_GET, REQUEST_PUT } from './API_Request'

export const state = {
  userProfile: {},
}

export const mutations = {
  async GET_USER_PROFILE(state) {
    try {
      const res = await REQUEST_GET('/api/me')
      const body = await res.json()
      state.userProfile = body
      return state
    } catch (e) {
      return e
    }
  },
  async UPDATE_PROFILE(state, data) {
    try {
      const { ...form } = data.form
      const res = await REQUEST_PUT(`/api/users/customers`, form)
      return res
    } catch (e) {
      return e
    }
  },
}

export const actions = {
  async getUserProfile() {
    return mutations.GET_USER_PROFILE(state)
  },
  async updateCustomerProfile(context, form) {
    return mutations.UPDATE_PROFILE(state, {
      form,
    })
  },
}

export const getters = {
  // Add Logic Before Computed
  userProfile(state) {
    // eslint-disable-line
    return state.userProfile
  },
}

export const users = {
  state,
  mutations,
  actions,
  getters,
}
