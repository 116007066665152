// matomo push
export default function pushTrack(route_name = "", path = window.location.href) {
    try {
        window.$tokenData?.u?.id && window["_paq"].push(['setUserId', window.$tokenData?.u?.id.toString()]);
        window["_paq"].push(['setDocumentTitle', route_name]);
        window["_paq"].push(['setCustomUrl', path.split('#accessToken')[0]]);
        // if (window.$extApp) {
        //     window["_paq"].push(['setCustomVariable',
        //         // Index, the number from 1 to 5 where this custom variable name is stored
        //         1,
        //         "extApp",
        //         window.$extApp,
        //         // Scope of the custom variable, "visit" means the custom variable applies to the current visit
        //         "visit"
        //     ]);
        // }

        // if (window.$extTokenDecode?.u?.id) {
        //     window["_paq"].push(['setCustomVariable',
        //         2,
        //         "extUId",
        //         window.$extTokenDecode?.u?.id,
        //         "visit"
        //     ]);
        // }

        window["_paq"].push(['trackPageView']);
    } catch (error) {
        console.error(error)
    }
}