<template>
  <div>
    <div id="alertMessage" class="hide">
      <!-- The Modal -->

      <!-- Modal content -->
      <div class="modal-content">
        <svg
          id="spinner"
          class="spinner"
          viewBox="0 0 40 40"
          with="30"
          height="30"
        >
          <circle cx="20" cy="20" r="18"></circle>
        </svg>
        <div id="alertBody"></div>
        <div class="actionConfirm hide wrapAction">
          <div id="cancelBtn" class="action">ปิด</div>
          <div id="confirmBtn" class="action">ยืนยัน</div>
        </div>
        <div class="actionInfo hide wrapAction">
          <div id="okBtn" class="action confirm">ตกลง</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  async mounted() {
    window.$alert = async (message = "", type = "") => {
      const alertBody = document.querySelector("#alertMessage #alertBody");
      const alertMessage = document.getElementById("alertMessage");
      const cancelBtn = document.querySelector("#alertMessage #cancelBtn");
      const okBtn = document.querySelector("#alertMessage #okBtn");
      const confirmBtn = document.querySelector("#alertMessage #confirmBtn");
      const actionConfirm = document.querySelector(
        "#alertMessage .actionConfirm"
      );
      const actionInfo = document.querySelector("#alertMessage .actionInfo");
      const wrapAction = document.querySelector("#alertMessage .wrapAction");

      const spinner = document.querySelector("#alertMessage #spinner");

      if (message === false) {
        alertBody.innerHTML = "";
        alertMessage.classList.add("hide");
        return;
      }

      alertBody.innerHTML = message;
      alertMessage.classList.remove("hide");
      wrapAction.classList.remove("hide");
      actionInfo.classList.add("hide");
      actionConfirm.classList.add("hide");
      spinner.classList.add("hide");

      return new Promise((resolve) => {
        const clickAction = (confirm) => {
          alertBody.innerHTML = "";
          alertMessage.classList.add("hide");
          // if (type == "confirm") {
          if (confirm) {
            resolve(true);
          } else {
            resolve(false);
          }
          // }
        };

        if (type == "confirm") {
          actionConfirm.classList.remove("hide");
        } else if (type === "loading") {
          wrapAction.classList.add("hide");
          spinner.classList.remove("hide");
        } else {
          actionInfo.classList.remove("hide");
        }

        okBtn.onclick = () => clickAction(true);
        confirmBtn.onclick = () => clickAction(true);
        cancelBtn.onclick = () => clickAction();
      });
    };
  },
};
</script>
<style lang="scss">
/* The Modal (background) */
#alertMessage {
  display: flex; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1090; /* Sit on top */
  // padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  // background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(255, 255, 255, 0.829); /* Black w/ opacity */
  transition: all 1s;
  font-size: 15px;
  color: rgb(78, 78, 78);
  /* Modal Content */
  .modal-content {
    // backdrop-filter: blur(10px);
    background-color: #fefefecc;
    // background-color: rgba(255, 255, 255, 0.4);
    margin: auto;
    text-align: center;
    // z-index: 2;
    padding: 15px 15px 10px 15px;
    // border: 1px solid #888;
    box-shadow: 0px 0px 9px 1px rgb(0 0 0 / 8%);
    border-radius: 10px;
    width: 80%;
    max-width: 300px;
  }

  #alertBody {
    // margin: 8px 0 16px 0;
    line-height: 30px;
    letter-spacing: 0.5px;
    font-size: unset !important;
  }

  #okBtn {
    background: #e2e2e2c2;
    // width: 50px;
    // height: 25px;
  }

  .danger {
    background: #b61717 !important;
    color: #ffffff;
  }

  #confirmBtn {
    background: var(--primary-color) !important;
    color: #ffffff;
  }

  #cancelBtn {
    background: #f3f3f3;
    // width: 56px;
    // height: 25px;
  }
  .actionInfo,
  .actionConfirm {
    display: flex;
    justify-content: center;
    margin: 10px 0 0 0;
  }
  .action {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0 5px 0 5px;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    &:active {
      background: rgba(226, 226, 226, 0.507);
    }
  }
  .hide,
  &.hide {
    display: none !important;
  }
}

svg.spinner circle {
  fill: transparent;
  stroke: #777777;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 125.6;
  transform-origin: 20px 20px 0;
  animation: spinner 2s linear infinite;
  animation-name: spinner;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 26.4;
  }

  50% {
    transform: rotate(720deg);
    stroke-dashoffset: 125.6;
  }

  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: 26.4;
  }
}

// @keyframes change-color {
//   0% {
//     stroke: #4a774b;
//   }

//   45% {
//     stroke: #4a774b;
//   }

//   50% {
//     stroke: #4a774b;
//   }

//   100% {
//     stroke: #4a774b;
//   }
// }
</style>