import { REQUEST_GET } from './API_Request'

export const state = {
  points: {
    data: [],
    total: 0,
    lastPage: 0,
  },
}

export const mutations = {
  async GET_HISTORY_POINTS(state, form) {
    try {
      const res = await REQUEST_GET('/api/me/points', form)
      const body = await res.json()

      state.points.data = body?.data || []
      state.points.total = body?.total || 0
      state.points.lastPage = body?.lastPage || 0
      return state
    } catch (e) {
      return e
    }
  },
}

export const actions = {
  async getHistoryPoints(context, form) {
    return mutations.GET_HISTORY_POINTS(state, form)
  },
}

export const getters = {
  // Add Logic Before Computed
  points(state) {
    // eslint-disable-line
    return state.points
  },
}

export const points = {
  state,
  mutations,
  actions,
  getters,
}
