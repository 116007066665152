import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#f7941e',
      },
    },
  },
}

const vuetify = new Vuetify(opts)

export default vuetify
