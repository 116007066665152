<template>
  <div class="history-amount-container">
    <MobileView>
      <v-data-table
        disable-sort
        :headers="headers"
        :items="pointList"
        :server-items-length="points && points.total"
        no-data-text="ไม่พบข้อมูล"
        no-results-text="ไม่พบข้อมูล"
        hide-default-footer
        mobile-breakpoint="0"
      >
        <template v-slot:item.name="{ item }">
          <div class="point-name">
            <span v-if="item.refType ==='expire'">
              <span>{{item.remark}} : </span>
            </span>
            <span v-else-if="item.campaign">
              <span>{{ item.campaign.name }} : </span>
            </span>
            <span v-else-if="item.receipt">
              <span>{{ item.receipt.shop.name }} : </span>
            </span>
            <span v-else>
              <span>{{ item.voucherCode.voucher.name }} : </span>
            </span>
            <span>{{ $numberWithCommas(item.amount) }} คะแนน</span>
            <div>{{ generateDate(item.createAt) }}</div>
          </div>
        </template>
        <template v-slot:item.amount="{ item }">
          <!-- <div>{{ item.type }}</div> -->
          <div class="amount-success" v-if="item.type === 'receive'">
            + {{ $numberWithCommas(item.amount) }}
          </div>
          <div class="amount-error" v-else>
            - {{ $numberWithCommas(item.amount) }}
          </div>
        </template>
      </v-data-table>
      <scroll-loader
        :loader-method="!isLoading ? () => getInitialData() : () => {}"
        :loader-disable="endList"
        :loader-size="0"
      >
      </scroll-loader>
    </MobileView>
  </div>
</template>

<script>
import moment from "moment";
import Utils from "@/mixin";

import MobileView from "@/components/MobileView";

export default {
  mixins: [Utils],
  components: {
    MobileView,
  },
  data: () => ({
    headers: [
      { text: "รายการ", value: "name" },
      {
        text: "คะแนน",
        value: "amount",
        width: "100px",
        align: "right",
        sortable: false,
      },
    ],
    pointList: [],
    isLoading: false,
    page: 1,
    endList: false,
  }),
  mounted() {
    window.loading(true);
    this.getInitialData();
    window.loading(false);
  },
  methods: {
    async getInitialData() {
      const { page } = this;
      let condition = {
        page,
      };
      this.isLoading = true;
      const res = await this.getHistoryPoints(condition);
      this.isLoading = false;
      const { data, lastPage } = res.points;

      // reduce(data, (a, b) => {
      //   a.push(b);
      //   return a;
      // });

      console.log("dd",data)


      this.pointList.push(...data);

      if (this.page >= lastPage) {
        this.endList = true;
      } else {
        this.page++;
      }
    },
    generateDate(n) {
      return n !== null ? moment(n).format("DD/MM/YYYY") : "-";
    },
  },
};
</script>

<style lang="scss">
$green: #34c759;
$red: #ff3b30;

.history-amount-container {
  .row {
    .col {
      padding: 0;
    }
  }
  .amount-success {
    color: $green;
  }
  .amount-error {
    color: $red;
  }
  .v-data-table {
    margin: 0 2px;
  }
  .point-name {
    line-height: 1;
  }
}
</style>