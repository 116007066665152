import Auth from './Auth'
import AuthLiff from '../utils/AuthLiff'
import { getCodeToken } from '@/middleware/Auth'
// eslint-disable-next-line no-unused-vars
export default async function (to, from, next) {
  window.loading(true)
  try {
    let pass = true

    if (to.meta.auth !== false && !window.$apiToken) {
      // getCodeToken first time for save to localStorage before liff init redirect
      await getCodeToken()
      console.log('-- set codeToken')
    }

    // this root route ignore liff ?
    if (to.matched[0].meta.authLiff !== false) {
      await AuthLiff()
    }

    if (to.meta.auth !== false) {
      pass = await Auth(to, from, next)
    }

    if (pass) {
      return next()
    }
  } catch (error) {
    console.error(error)
    return next({ name: 'error', params: error, replace: true })
  } finally {
    window.loading(false)
  }
}
