<template>
  <div id="default-layout">
    <slot />
  </div>
</template>

<script>
import Utils from "@/mixin";

export default {
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
#default-layout {
  min-height: 100vh;
  position: relative;
  .loading-process {
    z-index: 999;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.3);
    > div {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>