<template>
  <div class="login-container mt-10">
    <MobileView>
      <h1>เข้าสู่ระบบ</h1>
      <h3>สมาชิก เดอะ สตรีท พอยท์</h3>
      <v-form
        class="mt-10"
        v-model="valid"
        :lazy-validation="false"
        @submit.prevent="submitForm"
      >
        <v-text-field
          label="เบอร์โทรศัพท์ / Mobile Phone No."
          outlined
          dense
          color="black"
          v-model="phoneNumber"
          @keypress="validateNumeric"
          :rules="[rules.required]"
        />
        <v-btn class="primary" large block type="submit" :disabled="!valid"
          >ล็อคอิน</v-btn
        >
      </v-form>
      <div class="mt-10 text-center">
        ยังไม่ได้เป็นสมาชิก
        <router-link to="register">กดลงทะเบียนที่นี่</router-link>
      </div>
    </MobileView>
  </div>
</template>

<script>
import MobileView from "@/components/MobileView";

import {
  getCodeToken,
  getAuthToken,
  postAuth,
  checkTokenData,
} from "@/middleware/Auth";

export default {
  components: {
    MobileView,
  },
  data: () => ({
    flag: false,
    valid: false,
    phoneNumber: "",
    rules: {
      required: (value) => !!value || "Require field.",
    },
  }),
  async beforeCreate() {
    try {
      // console.log("before");
      window.loading(true);
      const app_access_token = await getCodeToken();
      window.$authToken = await getAuthToken(app_access_token);
      // console.log("-- set authToken");
      this.flag = true;
      window.loading(false);
    } catch (err) {
      window.loading(false);
      this.$router.replace({
        name: "error",
        params: err,
      });
    }
  },
  methods: {
    validateNumeric(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode >= 48 && charCode <= 57) {
        return true;
      } else {
        evt.preventDefault();
      }
    },
    async checkLogin() {
      const res = await postAuth(
        {
          channel: "line",
          credential: {
            accessToken: window.$liffData?.accessToken,
          },
        },
        false
      );
      const { message } = res;
      if (message !== "Invalid credential") {
        window.$apiToken = res;
        console.log("-- set api token");
        window.$tokenData = checkTokenData(window.$apiToken);
        console.log("-- set token data");
        this.$router.push({ name: "Point-History" });
      }
    },
    async submitForm() {
      const { phoneNumber, valid } = this;
      window.loading(true);
      try {
        if (!valid) {
          window.$alert("กรุณากรอกข้อมูลให้ครบถ้วน");
          return;
        }

        const res = await postAuth(
          {
            channel: "phone",
            credential: { phone: phoneNumber },
            link: {
              channel: "line",
              accessToken: window.$liffData?.accessToken,
            },
          },
          false
        );
        const { message, access_token } = res;
        if (message === "Invalid credential") {
          this.$router.replace("/register");
        }
        if (access_token) {
          // console.log("- set api token");
          window.$apiToken = access_token;
          window.$tokenData = checkTokenData(window.$apiToken);
          // console.log("- set user data");
          this.$router.replace("/point-history");
        }
        window.loading(false);
      } catch (err) {
        window.loading(false);
        const message =
          err.message ||
          `กรุณาเข้าใช้งานใหม่ภายหลัง หรือติดต่อ 
          <p class="word">เจ้าหน้าที่</p>`;
        window.$alert(message);
      }
    },
  },
  watch: {
    flag: {
      handler() {
        this.checkLogin();
      },
      deep: false,
    },
  },
};
</script>