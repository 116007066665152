import Vue from 'vue'
import VueRouter from 'vue-router'

import Middleware from '../middleware/Middleware'

import Home from '@/views/Home.vue'

import Login from '@/views/Login.vue'
import Register from '@/views/Register/index.vue'
import RegisterSuccess from '@/views/Register/Success.vue'

import PersonalInformation from '@/views/Auth/PersonalInformation.vue'
import PointHistory from '@/views/Auth/PointHistory.vue'
import Setting from '@/views/Auth/Setting.vue'

import Tracking from '../utils/Tracking'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      auth: false,
      layout: 'homeLayout',
    },
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      layout: 'homeLayout',
      auth: false,
    },
    component: Register,
  },
  {
    path: '/register-success',
    name: 'Register-Success',
    meta: {
      layout: 'homeLayout',
      auth: false,
    },
    component: RegisterSuccess,
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      auth: false,
      layout: 'homeLayout',
    },
    component: Home,
    redirect: '/point-history',
  },
  {
    path: '/personal-information',
    name: 'Personal-Information',
    component: PersonalInformation,
  },
  {
    path: '/point-history',
    name: 'Point-History',
    meta: {
      layout: 'authLayout',
    },
    component: PointHistory,
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: {
      layout: 'authLayout',
    },
    component: Setting,
  },
  {
    path: '/error',
    name: 'error',
    props: true,
    meta: {
      authLiff: false,
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/ErrorPage.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    meta: {
      authLiff: false,
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "notFound" */ '../views/NotFoundPage.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // console.log(to, from)
  window.$prevRoute = from

  if (!to.matched.length) {
    return next({ name: 'notfound' })
  }

  Middleware(to, from, next)

  // next()
  window.loading(false)
})

router.afterEach((to) => {
  Tracking(to.name, to.fullPath)
})

export default router
