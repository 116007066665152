const m = {
  default: "พบบางอย่างผิดปกติ กรุณาเข้าใช้งานใหม่ภายหลัง หรือติดต่อเจ้าหน้าที่",
  noPermission: "ไม่พบสิทธิ์เข้าถึงส่วนนี้ กรุณาติดต่อผู้ดูแลระบบ",
  noAccess: "ไม่พบสิทธิ์การเข้าใช้ กรุณาติดต่อผู้ดูแลระบบ",
  noAccessPassword:
    "ไม่พบข้อมูลตรงกับระบบ กรุณาตรวจสอบข้อมูล หรือติดต่อผู้ดูแลระบบ",
};

export default (code = 100) => {
  const message =
    {
      100: m.default,
      101: m.noAccess,
      102: m.noAccess,
      103: m.noAccess,
      104: m.noAccess,
      105: m.noAccess,
      106: m.noAccess,
      107: m.noAccess,
      108: m.noAccessPassword,
      109: m.default,
      111: m.default,
      202: m.noPermission,
      301: m.default,
      302: m.default,
      401: m.default,
      402: m.default,
    }[code] || null;

  return { message, code };
};
