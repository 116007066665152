<template>
  <v-app>
    <component :is="layout">
      <v-main
        :class="`${
          checkSetting ? 'auth-setting' : checkAuthLayout ? 'auth-main' : ''
        }`"
      >
        <router-view />
      </v-main>
    </component>
    <Alert />
  </v-app>
</template>

<script>
import Alert from "@/components/Alert";

export default {
  name: "App",
  components: { Alert },
  computed: {
    layout() {
      return this.$route.meta.layout || "defaultLayout";
    },
    checkAuthLayout() {
      return this.$route.meta.layout === "authLayout" ? true : false;
    },
    checkSetting() {
      const { name } = this.$route;
      return name === "Setting" ? true : false;
    },
  },
};
</script>

<style lang="scss">
$gray: #f2f3f5;

.v-main {
  background: $gray;
  height: 100%;
  &.auth-main {
    height: calc(100% - (245px + 74px));
  }
  &.auth-setting {
    height: calc(100% - (245px));
  }
}
</style>
