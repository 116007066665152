<template>
  <CustomModal
    disableCancel
    :modal="modal"
    @confirm="toggle"
    :data="confirmData"
  >
    <template v-slot:header>
      <div class="market-header" v-if="detail">
        {{ detail.title }}
      </div>
    </template>
    <template v-slot:body>
      <div class="container market-content" v-if="detail">
        <div v-html="detail.content" />
      </div>
    </template>
  </CustomModal>
</template>

<script>
import CustomModal from "@/components/Modal";

export default {
  components: {
    CustomModal,
  },
  props: {
    confirmData: {
      type: Object,
      default: () => ({
        confirmText: "ปิด",
      }),
    },
    detail: {
      type: Object,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
    },
  },
};
</script>

<style lang="scss">
.market-header,
.market-content {
  h1 {
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
  }
}
</style>