<template>
  <div id="home-layout">
    <slot />
  </div>
</template>

<script>
import Utils from "@/mixin";

export default {
  mixins: [Utils],
};
</script>

<style lang="scss" scoped>
#home-layout {
  min-height: 100vh;
}
</style>