<template>
  <div class="setting-container">
    <MobileView>
      <div class="content-section">
        <div class="d-flex check-box-input mt-3">
          <v-checkbox
            class="mt-0 pt-0"
            v-model="formData.isAccept"
            hide-details
          />
          <span>
            ฉันต้องการรับข้อมูลข่าวสารทางการตลาด<br />
            <span
              class="cursor-pointer primary--text"
              @click.stop="toggleMarket"
            >
              รายละเอียดการให้ความยินยอม
            </span>
          </span>
        </div>
        <div v-if="consentList !== null">
          <div
            class="d-flex check-box-input mt-2"
            v-for="(item, index) in consentList"
            :key="index"
          >
            <v-checkbox
              class="mt-0 pt-0"
              v-model="item.check"
              hide-details
              readonly
              :ripple="false"
            />
            <span>
              ข้าพเจ้าได้อ่านและยอมรับ
              <span
                class="cursor-pointer primary--text"
                @click.stop="getTermCon(index)"
              >
                ข้อตกลงและเงื่อนไข
              </span>
              สมาชิก เดอะ สตรีท พอยท์
            </span>
          </div>
        </div>
      </div>
      <div class="button-section">
        <div>
          โปรดศึกษา
          <a
            color="primary"
            href="https://www.thestreetratchada.com/upload/pdpa/2-customer.pdf"
            target="_blank"
            >นโยบายความเป็นส่วนตัวสำหรับลูกค้า</a
          >
        </div>
        <div class="my-2">
          หากท่านมีความประสงค์ที่จะเปลี่ยนแปลงข้อมูลส่วนตัวสามารถแจ้งรายละเอียดมาที่
          <u>marketing@thestreet.co.th</u>
        </div>
        <v-btn color="#f7941e" block @click="submitAction"> บันทึก </v-btn>
      </div>
    </MobileView>
    <TermCon
      :modal="termModal"
      :toggle="toggleTermCon"
      :detail="consentDetail"
    />
    <Market
      :modal="marketModal"
      :toggle="toggleMarket"
      :detail="mktConsentDetail"
    />
  </div>
</template>

<script>
import { forEach, size } from "lodash";
import MobileView from "@/components/MobileView";

import Market from "../Register/Market.vue";
import TermCon from "../Register/TermCon.vue";

import {
  acceptMktConsent,
  checkConsent,
  checkMktConsent,
  getConsent,
  getMktConsent,
} from "@/middleware/Auth";

export default {
  components: {
    MobileView,
    Market,
    TermCon,
  },
  data: () => ({
    formData: {
      isAccept: false,
    },
    marketModal: false,
    termModal: false,
    consentList: null,
    consentDetail: null,
    mktConsentDetail: null,
  }),
  methods: {
    async getInitialData() {
      window.loading(true)
      const consent = await getConsent();
      const mktConsent = await getMktConsent();
      const checkTerm = await checkConsent();
      const checkMkt = await checkMktConsent();
      let newConsent = [];
      forEach(consent, (i) => {
        if (size(checkTerm) > 0) {
          forEach(checkTerm, (j) => {
            if (i.id === j.id) newConsent.push({ ...i, check: false });
            else newConsent.push({ ...i, check: true });
          });
        } else {
          newConsent.push({ ...i, check: true });
        }
      });
      this.consentList = newConsent;
      this.mktConsentDetail = mktConsent;
      if (checkMkt === null) {
        this.formData.isAccept = true;
      }
      window.loading(false)
    },
    async submitAction() {
      const { isAccept } = this.formData;
      window.loading(true)
      const res = await acceptMktConsent(isAccept);
      const { status } = res;
      window.loading(false)
      if (status === "success") {
        window.$alert("บันทึกสำเร็จ");
      } else {
        window.$alert(res);
      }
    },
    getTermCon(index) {
      const { consentList } = this;
      this.consentDetail = consentList[index];
      this.toggleTermCon();
    },
    toggleTermCon() {
      this.termModal = !this.termModal;
    },
    toggleMarket() {
      this.marketModal = !this.marketModal;
    },
  },
  mounted() {
    this.getInitialData();
  },
};
</script>

<style lang="scss" scoped>
$grey: rgba(0, 0, 0, 0.6);

.setting-container {
  .button-section {
    padding: 0 20px;
    position: fixed;
    bottom: 10px;
    @media (min-width: 600px) {
      max-width: 50%;
    }
    @media (min-width: 960px) {
      max-width: 33.33%;
    }
  }
  .check-box-input {
    color: $grey;
    font-size: 16px;
    .cursor-pointer {
      cursor: pointer;
    }
  }
}
</style>