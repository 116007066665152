import { mapActions, mapGetters } from 'vuex'

import { actions, getters, modules } from '../store'

function getMapping(type) {
  const functionNames = []

  if (type === 'getters') {
    Object.keys(getters).forEach((key) => {
      if (typeof getters[key] === 'function') {
        functionNames.push(key)
      }
    })
  }

  if (type === 'actions') {
    Object.keys(actions).forEach((key) => {
      if (typeof actions[key] === 'function') {
        functionNames.push(key)
      }
    })
  }

  Object.values(modules).forEach((module) => {
    if (typeof module === 'object' && module?.getters) {
      Object.keys(module?.[type]).forEach((key) => {
        if (typeof module?.[type][key] === 'function') {
          functionNames.push(key)
        }
      })
    }
  })

  return functionNames
}

//Global computed and methods
export default {
  //Store getters
  computed: mapGetters(getMapping('getters')),
  //Store actions
  methods: {
    ...mapActions(getMapping('actions')),
  },
}
